body {
  margin: 0;
  font-family: 'Arial', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page {
  background-color: black;
  color: white;
  font-size: 18px;
}

.paper {
  width: 100%;
  background-color: white;
  color: black;
}

.paper-content-wrapper {
  padding: 50px 8%;
}

.paper-content {
  max-width: 960px;
  margin: 0 auto;
}

.paper-header {
  position: relative;
  font-size: 40px;
}

.paper-header a {
  cursor: pointer;
}

.text-item {
  padding-bottom: 3em;
}

.text-title {
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: none;
}

.text-description {
  font-size: 18px;
  color: #646464;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.menu-item {
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px;
}

.menu-name {
  text-align: center;
  color: white;
  background: rgba(0,0,0,0.1);
  text-shadow: 0 0 6px black;
}

.menu-title {
  font-size: 24px;
}

.menu-image {
  width: 100%;
  padding: 50% 0;
  overflow: hidden;
}

.navbar-content {
  max-width: 800px;
  margin: 0 auto;
}

.navbar-items {
  width: 100%;
  background-color: rgba(0,0,0,0.6);
  position: fixed;
  top: 0;
  z-index: 100;
}

.navbar-item {
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
}

.navbar-item .active {
  font-weight: bold;
}

.a, a:hover, a:visited {
  text-decoration: none;
}

.call-button {
  background-color: rgba(0,0,0,0.6);
  border-radius: 8px;
  padding: 6px 30px; 
  font-size: 16px;
  color: white !important;
}

.order-button {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 6px 30px; 
  font-size: 23px;
  text-transform: uppercase;
  font-weight: 500;
  color: black !important;
}

.scroll-button {
  color: white !important;
  cursor: pointer;
}

.scroll-text {
  font-size: 16px;
}

.scroll-icon {
  font-size: 30px;
}

.scroll-button .scroll-text, .scroll-button:hover .scroll-icon {
  display: none;
}
.scroll-button .scroll-icon, .scroll-button:hover .scroll-text {
  display: block;
}

.modal-announcement {
  margin: 30vh auto;
}

.modal-header {
  border-bottom: 0;
}

.modal-content {
  background-color: rgba(0,0,0,0.6);
  color: #ffffff;
}

.modal-body {
  white-space: pre-line
}